import { ProjectHead } from "./ProjectHead";

const ProjectPage = () => {
    const projects = [
        {
            title: "Phishing Website Classification",
            description: "Developed special kernels for analyzing categorical variables in website data, allowing for more accurate identification of secure and malicious websites by taking into account the unique qualities and relationships of the variables.",
            link1: null,
            link2: "https://github.com/CarlOwOs/Phishing-Websites-Classification",
            link2_name: "github.com"
        },
        {
            title: "UPC Space Program",
            description: "Designed a radio-based data link using LoRa WAN, Raspberry Pi, and 4G technologies for our Near Space Probe (HAB) to ensure reliable and cost-effective communication with the module.",
            link1: null,
            link2: "https://www.upcprogram.space/zephyros",
            link2_name: "upcprogram.space"
        },
        {
            title: "COVID Cough Detection",
            description: "Won a hackathon with a Python-based Telegram bot that uses Deep Learning to classify cough audio files and distinguish between COVID-related and non-COVID-related coughs.",
            link1: null,
            link2: "https://github.com/CarlOwOs/Apolo-COVID-cough-predictor",
            link2_name: "github.com"
        },
        {
            title: "Restaurant Recommendation System for Groups",
            description: "Developed a Hackathon-winning Python-based web app (Streamlit) that helps groups find the perfect restaurant based on prices, location, ratings, and reviews from both users and restaurants.",
            link1: null,
            link2: "https://github.com/megaelius/Delfos-datathon-fme",
            link2_name: "github.com"
        },
        {
            title: "Heart Failure Monitoring",
            description: "As team leader of a 10-person team, collaborated with doctors at the Vall d'Hebron Heart Failure Unit to develop a dashboard for heart failure patient data visualization, risk prediction, and time series analysis.",
            link1: null,
            link2: "https://github.com/CarlOwOs/VH_and_PE_codes",
            link2_name: "github.com"
        },
        {
            title: "ICASSP 2023 Publication",
            description: "Paper accepted at ICASSP 2023 in which we propose a framework to study the geometric structure of the data. We make use of our recently introduced non-negative kernel (NNK) regression graphs to estimate the point density, intrinsic dimension, and the linearity of the data manifold (curvature).",
            link1: null,
            link2: "https://arxiv.org/abs/2210.17475",
            link2_name: "arxiv.org",
            link3: "https://github.com/CarlOwOs/NNK-ID",
            link3_name: "github.com"
        },
        {
            title: "This Website!",
            description: "I created a website to serve as a gateway for me to learn web development. It was a fun and challenging project where I used React.js to build everything from scratch. I'm really proud of how it turned out and I hope you like it too!",
            link1: null,
            link2: "https://carloshurtado.xyz",
            link2_name: "carloshurtado.xyz"
        },
        {
            title: "RoastReviews: Review Summarization",
            description: "As a hackathon project, created a web app that uses NLP to analyze reviews for coffee shops and provide ratings on relevant categories. The application uses the Cohere API to analyze Yelp reviews of coffee shops and provide ratings on categories of our choice. We are also able to get dish recommendations based on the reviews.",
            link1: null,
            link2: "https://github.com/CarlOwOs/RoastReviews",
            link2_name: "github.com"
        },
        {
            title: "Cell area estimation",
            description: "A couple of Python scripts that use semantic segmentation to estimate the area of cells in a microscopy image. I wrote this to help my brother with his research in cell biology. He originally used ImageJ and manually drew the cell boundaries, but this script automates the process, saving hours of cell-counting time.",
            link1: null,
            link2: "https://github.com/CarlOwOs/count-cells",
            link2_name: "github.com"
        }
    ];

    return (
        <div className="page">
            <h1>Projects</h1>
            {projects.reverse().map((project, index) => (
                <ProjectHead 
                    key={index}
                    title={project.title}
                    description={project.description}
                    link1={project.link1}
                    link2={project.link2}
                    link2_name={project.link2_name}
                    link3={project.link3}
                    link3_name={project.link3_name}
                />
            ))}
        </div>
    );
};

export {ProjectPage};